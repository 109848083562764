import React from "react"
import qs from "../utils/qs"

const BASE_URL = "https://www.vectary.com/viewer/v1"

export interface IVectary {
  id: string,
  params?: Object,
  className?: string
}

const Vectary: React.FC<IVectary> = ({ id, params, ...props }) => {
  const options = qs({
    model: id,
    enableApi: 1,
    ...params
  })

  return <iframe
    id={id}
    src={`${BASE_URL}?${options}`}
    frameBorder="0"
    width="100%"
    height="480"
    {...props}
  />
}

export default Vectary
