const _filterParams = ([ key, value ]) => {
  if(value === false || value === 0) return true
  if(value && value.length === 0) return false
  return !!value
}

const _stringifyKeyValue = ([ key, value ]) => `${key}=${value}`

export default (o: Object): string => Object.entries(o)
  .filter(_filterParams)
  .map(_stringifyKeyValue)
  .join("&")
