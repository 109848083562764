import React from "react"
import Vectary from "../components/Vectary"

const ModellenPage = () => (
  <main
    id="modellen-page"
    className="pb-8"
    style={{
      height: "calc(100vh - (127px + 178px + 64px))"
    }}
  >
    <Vectary
      id="1c32a549-b5fc-4339-8af9-7479c3e5f186"
      params={{ env: "teufelsbergground2" }}
      className="h-full"
    />
  </main>
)

export default ModellenPage
